import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ImgScreenshot from '../images/ImgScreenshot';
import ImgLogo from '../images/ImgLogo';
import Badges from './Badges';

const Header = props => (
  <Content>
    <TopSlice>
      <ImgLogo
        fadeIn={false}
        imgStyle={logoStyles}
        alt="Shophilist logo, remotely represents a winking pink fridge."
      />
      <H1>Shophilist</H1>
      <Text>Simplify your shopping experience with one shareable list.</Text>
      <TextLink>
        <Link to="/story/">Why I built this?</Link>
      </TextLink>
    </TopSlice>
    <ScreenshotContainer>
      <ImgScreenshot
        fadeIn={false}
        alt="Screenshot of the Shophilist mobile application."
      />
      <Overlay />
    </ScreenshotContainer>
    <BadgesContainer>
      <Badges />
    </BadgesContainer>
  </Content>
);

const Content = styled.header`
  background: #fbf6d9;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  max-height: 100vh;
  padding: 2rem;
  padding-top: 3rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  @media screen and (max-height: 42rem) {
    min-height: 40rem;
  }
`;

const TopSlice = styled.div`
  position: relative;
`;

const BadgesContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2vh;

  @supports (bottom: max(0px)) {
    bottom: 0;
  }
`;

const H1 = styled.h1`
  font-size: 3rem;
  padding-bottom: 1rem;
  font-weight: 600;

  @media screen and (max-height: 45rem) {
    font-size: 2.5rem;
    padding-bottom: 0rem;
  }
`;
const Text = styled.p`
  color: #63581e;
  font-family: 'Dosis', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;

  @media screen and (max-height: 45rem) {
    font-size: 1.3rem;
  }
`;
const TextLink = styled.p`
  padding-bottom: 1rem;
`;

const logoStyles = {
  width: '130px',
  height: '130px',

  '@media screen and (maxHeight: 42rem)': {
    width: '120px',
    height: '120px'
  }
};

const ScreenshotContainer = styled.div`
  min-width: 200px;
  max-width: 400px;
  width: 35vh;
`;

const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(hsla(51, 81%, 92%, 0), #fff6b9);
  width: 100vw;
  height: 25vh;
`;

export default Header;
