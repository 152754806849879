import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ImgLogo = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(
            width: 130
            height: 130
            traceSVG: {
              color: "#f3637b"
              optTolerance: 0.4
              turdSize: 100
              turnPolicy: TURNPOLICY_MAJORITY
            }
          ) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);
  return <Img fixed={data.placeholderImage.childImageSharp.fixed} {...props} />;
};
export default ImgLogo;
