import React from 'react';
import styled from 'styled-components';
import Badges from './Badges';

const MainContent = props => (
  <Container>
    <FeaturesList>
      <H2Features>Features</H2Features>
      <HR />
      <Feature>
        <Dot />
        <H3Features>One shareable list</H3Features>
        <Text>
          Just a simple list you can share with your friend, roommate or
          signifigant other.
        </Text>
      </Feature>
      <Feature>
        <Dot />
        <H3Features>Real time collaboration</H3Features>
        <Text>
          Your list is always up-to-date. No need to perform pull-to-refresh
          every five seconds!
        </Text>
      </Feature>
      <Feature>
        <Dot />
        <H3Features>Push notifications</H3Features>
        <Text>
          As annoying as they are you might not want to miss it when someone
          adds{' '}
          <span role="img" aria-label="banana emoji">
            🍌
          </span>
          to the list. <small> (Turned off by default)</small>
        </Text>
      </Feature>
    </FeaturesList>
    <AppStores>
      <H2AppStores>Available on both iOS and Android</H2AppStores>
      <Badges />
      {/* <Form
        name="waiting-list"
        action="/success/"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <Text>Be the first to know when we launch!</Text>
        <div>
          <Label htmlFor="email">Email</Label>
          <Field type="text" name="email" id="email" placeholder="Your email" />
          <SubmitButton type="submit" value="Send" />
        </div>
      </Form> */}
    </AppStores>
  </Container>
);

const Container = styled.div``;

const FeaturesList = styled.section`
  position: relative;
  padding: 2rem 3.7rem;
  background: #fff6b9;
  /* box-shadow: 0 0 14px rgba(98, 88, 30, 0.11),
    0 -1px 10px rgba(98, 88, 30, 0.11); */
  color: #757b7e;
`;

const Feature = styled.div`
  position: relative;
`;

const H2Features = styled.h2`
  font-size: 1.25rem;
  letter-spacing: 1px;
  font-weight: 700;
`;
const H3Features = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;
const Text = styled.p`
  font-size: 0.95rem;
`;

const HR = styled.hr`
  height: 4px;
  background: #eae299;
  border-radius: 2px;
  border: 0;
`;

const AppStores = styled.section`
  color: #fff6b9;
  background: #f1647d;
  padding: 3rem 0;
  text-align: center;
`;

const H2AppStores = styled.h2`
  margin-top: 0;
  font-weight: 500;
  font-size: 1.25rem;
`;

const Dot = styled.div`
  position: absolute;
  top: 0.9rem;
  left: -1.7rem;
  width: 12px;
  height: 4px;
  background: #ebe299;
  border-radius: 2px;
`;

// const Form = styled.form`
//   box-shadow: 0px -48px 0px -47px #dc5c73;
//   padding-top: 1rem;
//   margin-top: 2rem;

//   p {
//     margin-bottom: 1rem;
//   }
//   > div {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// `;

// const Label = styled.label`
//   font-size: 0.95rem;
//   position: absolute;
//   clip: rect(1px, 1px, 1px, 1px);
// `;

// const Field = styled.input`
//   line-height: 2rem;
//   border: 0;
//   font-size: 1.2rem;
//   background-color: #d0576c;
//   font-family: 'Dosis', sans-serif;
//   padding: 0.2rem 1rem;
//   color: #fff6b9;
//   border-radius: 1rem 0 0 1rem;
//   transition: box-shadow 0.2s;

//   box-shadow: 0px 0px 10px rgba(255, 255, 255, 0),
//     0px 2px 14px rgba(255, 247, 182, 0), 0px 0px 3px rgba(255, 247, 182, 0);

//   ::placeholder {
//     color: #ffaab9;
//   }

//   &:focus {
//     outline: none;
//     box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1),
//       0px 2px 14px rgba(255, 247, 182, 0.1),
//       0px 0px 3px rgba(255, 247, 182, 0.7);
//   }
// `;

// const SubmitButton = styled.input`
//   line-height: 2rem;
//   text-transform: uppercase;
//   border: 0;
//   font-size: 1.2rem;
//   padding: 0.2rem 1.2rem 0.2rem 1rem;
//   font-family: 'Dosis', sans-serif;
//   font-weight: 600;
//   background-color: #fff6b9;
//   color: #f1647d;
//   border-radius: 0 1rem 1rem 0;
// `;

export default MainContent;
