import React from 'react';
import SEO from '../components/seo';
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import Layout from '../components/layout';

const App = props => (
  <Layout>
    <SEO
      title="Shophilist App"
      keywords={[
        `shophilist`,
        `mobile app`,
        `app`,
        `shopping list`,
        `groceries`
      ]}
    />
    <Header />
    <MainContent />
  </Layout>
);

export default App;
